.history-container {
  border-radius: 4px;
}

.history-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  cursor: pointer;
  transition: all 0.3s ease;
  background: var(--theme-bg-color);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid var(--theme-off-border-color);
  &:hover {
    border-color: var(--primary-color);
  }
}

.toast-top-center {
  top: 10px;
}

#toast-container {
  pointer-events: none;
  transition: transform 0.23s cubic-bezier(0.21, 1.02, 0.73, 1);

  & > .toast {
    line-height: 1.4;
    box-shadow: none;
    border-radius: 2px;
    background-position: 20px center;
    padding: 15px 15px 15px 60px;
    border-left: 5px solid white;
    width: 100%;
    max-width: 400px;
  }

  .ngx-toastr {
    background: rgba(var(--rgb-theme-bg), 0.6);
    backdrop-filter: blur(5px);
    background-size: 16px;
    border-radius: 4px;
    box-shadow:
      0 3px 10px rgba(0, 0, 0, 0.1),
      0 3px 3px rgba(0, 0, 0, 0.05);
    color: var(--theme-font-color);
    max-width: 350px;
    pointer-events: auto;
    will-change: transform;
    border-left: 3px solid white;
    padding: 15px;
    line-height: 1;
    max-height: 150px;
    overflow: hidden;

    &:hover {
      max-height: unset;
    }

    &.toast-success {
      border-color: var(--green-color);
    }
    &.toast-error {
      border-color: var(--red-color);
    }
    &.toast-info {
      border-color: var(--blue-color);
    }
    &.toast-warning {
      border-color: var(--orange-color);
    }
  }

  .toast-title {
    // line-height: 1.2;
    // text-transform: uppercase;
    font-weight: 600;
    font-size: fontsize(14);
    letter-spacing: 0.5px;
    margin-bottom: 3px;
  }

  .toast-close-button {
    font-weight: normal;
    text-shadow: none;
    transition: all 0.3s ease;
    line-height: 1;
    color: var(--theme-font-color);
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  .toast-message {
    font-size: fontsize(14);
  }
}

nz-alert {
  text-align: center;
}

:root {
  --hot-toast-color: var(--theme-font-color);
  --hot-toast-bg: var(--theme-bg-color);
}

.hot-toast-close-btn {
  &:before {
    content: '\00D7';
    font-size: 16px;
    line-height: 1;
    color: var(--theme-font-color);
  }
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
}

.ant-layout {
  background: transparent;
}

.ant-modal-mask {
  background: rgba(var(--rgb-theme-bg), 0.45);
  backdrop-filter: blur(2px);
}

.ant-modal-footer {
  @include clearfix;
  border-color: var(--theme-border-color);
}

.ant-modal-header {
  background: transparent;
  color: var(--theme-font-color);
  border-color: var(--theme-border-color);
}

.ant-modal-close-x,
.ant-modal-close {
  color: var(--theme-font-color);
}

.ant-modal-content {
  @extend .glassy-border;
  background: rgba(var(--rgb-theme-bg), 0.5);
  backdrop-filter: blur(10px);
  color: var(--theme-font-color);
  border-radius: 4px;
}

.ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm-body .ant-modal-confirm-content {
  color: var(--theme-font-color);
}
.ant-btn-primary {
  background-color: var(--primary-color);
}

.anticon {
  vertical-align: middle;
}

.ant-checkbox-wrapper {
  color: var(--theme-font-color);
}

.ant-collapse {
  background: var(--theme-off-bg-color);
  color: var(--theme-font-color);
  border-color: var(--theme-border-color);

  > .ant-collapse-item {
    border-color: var(--theme-border-color);

    > .ant-collapse-header {
      color: var(--theme-font-color);
    }
  }
}

.ant-collapse-content {
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
  border-color: var(--theme-border-color);
}

.ant-list {
  color: var(--theme-font-color);
}

.ant-list-bordered {
  border-color: var(--theme-border-color);

  .ant-list-item {
    border-color: var(--theme-border-color);
  }
}

.ant-list-item {
  color: var(--theme-font-color);
}

.ant-list-split .ant-list-item {
  border-color: var(--theme-border-color);
}

.ant-list-item-action-split {
  background-color: var(--theme-border-color);
}

.ant-list-empty-text {
  color: var(--theme-font-color);
}

.ant-dropdown-menu {
  @extend .glassy-border;
  background: rgba(var(--rgb-theme-bg), 0.7);
  backdrop-filter: blur(5px);
  color: var(--theme-font-color);
  padding: 5px;
  border-radius: 4px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  background: transparent;
  color: var(--theme-font-color);
  border-radius: 4px;

  &:hover {
    background: rgba(var(--rgb-theme-off-bg), 0.5);
  }
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  background: var(--theme-border-color);
}
.ant-dropdown-menu-item
  .ant-dropdown-menu-submenu-expand-icon
  .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title
  .ant-dropdown-menu-submenu-expand-icon
  .ant-dropdown-menu-submenu-arrow-icon {
  color: var(--theme-font-color);
}

.ant-form-item {
  color: var(--theme-font-color);

  label {
    color: var(--theme-font-color);
  }
}

.ant-form-item-label > label {
  color: var(--theme-font-color);
}

.ant-input {
  background: rgba(var(--rgb-theme-off-bg), 0.5);
  border-color: var(--theme-border-color);
  color: var(--theme-font-color);
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  border-color: var(--primary-color);
}

.ant-form-item-has-error .ant-input:not(.ant-input-disabled),
.ant-form-item-has-error
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
.ant-form-item-has-error .ant-input:not(.ant-input-disabled):hover,
.ant-form-item-has-error
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  background-color: var(--theme-off-bg-color);
}

.ant-input-number {
  background: var(--theme-off-bg-color);
  border-color: var(--theme-border-color);
}

.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--primary-color);
}

.ant-input-number-handler-wrap {
  background: var(--theme-off-bg-color);
  border-color: var(--theme-border-color);
  color: var(--theme-font-color);
}

.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner,
.ant-input-number-handler {
  color: var(--theme-font-color);
}

.ant-select {
  color: var(--theme-font-color);
  background: rgba(var(--rgb-theme-off-bg), 0.2);
  width: 100%;
  border-radius: 4px;
}
.ant-select-clear {
  background: var(--theme-off-bg-color);
  color: var(--theme-font-color);
}
.ant-select-tree {
  background: var(--theme-off-bg-color);
  color: var(--theme-font-color);
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background: rgba(var(--rgb-primary), 0.15);
}
.ant-select-tree
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background: rgba(var(--rgb-primary), 0.15);
}

.ant-select-selection {
  background: var(--theme-off-bg-color);
  color: var(--theme-font-color);
  border-color: var(--theme-border-color);
}

.ant-select-dropdown {
  background: var(--theme-off-bg-color);
  color: var(--theme-font-color);
}

.ant-select-item {
  color: var(--theme-font-color);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: var(--theme-bg-color);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--theme-border-color);
  color: var(--theme-font-color);
}

.ant-select-arrow {
  color: var(--theme-border-color);
}

.ant-select-dropdown-menu-item {
  color: var(--theme-font-color);
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: var(--theme-bg-color);
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: var(--theme-bg-color);
  color: var(--primary-color);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: rgba(var(--rgb-theme-off-bg), 0.2);
  border-color: var(--theme-border-color);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--primary-color);
}

.ant-radio-wrapper {
  color: var(--theme-font-color);
}
.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color);
}
.ant-radio-checked::after {
  border-color: var(--primary-color);
}
.ant-radio-inner::after {
  background-color: var(--primary-color);
}

.ant-tabs {
  color: var(--theme-font-color);
}

.ant-tabs-bar {
  border-color: var(--theme-border-color);
}

.ant-tooltip-inner {
  background: rgba(var(--rgb-theme-bg), 0.7);
  backdrop-filter: blur(5px);
  color: var(--theme-font-color);
}

.ant-tooltip-arrow::before,
.ant-tooltip-arrow-content::before {
  background: rgba(var(--rgb-theme-bg), 0.7);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: var(--primary-color);
  border-color: var(--primary-color);
  transition: all 0.3s ease;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
  border-color: transparent;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: var(--primary-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-switch-checked {
  background-color: var(--primary-color);
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-color: var(--theme-border-color);
}

.ant-tabs-tab:hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--theme-font-color);
  font-weight: normal;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0;
}

.ant-tabs-ink-bar {
  background-color: var(--primary-color);
}
.ant-table.ant-table-small,
.ant-table {
  background-color: var(--theme-bg-color);
  color: var(--theme-font-color);
  opacity: 0.8;
  font-size: fontsize(12);
}

.ant-table-thead > tr > th,
.ant-table-small .ant-table-thead > tr > th {
  background-color: var(--theme-bg-color);
  border-color: var(--theme-border-color);
  color: var(--theme-font-color);
  font-size: fontsize(12);
}

.ant-table-thead > tr > th {
  text-transform: uppercase;
}

.ant-table-tbody > tr > td {
  border-color: var(--theme-border-color);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: var(--theme-off-bg-color);
}

.ant-popover-inner {
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
}
.ant-popover-title {
  border-color: var(--theme-border-color);
  color: var(--theme-font-color);
}
.ant-popover-inner-content {
  color: var(--theme-font-color);
}
.ant-popover-arrow-content {
  background-color: var(--theme-bg-color);
}

.main-view-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;

  & > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-tab {
    padding: 0;
  }

  .ant-tabs-tab-btn {
    padding: 5px 8px;
    font-size: fontsize(13);
    opacity: 0.7;
  }
  .ant-tabs--status-enabled {
    &::after {
      content: ' \2219';
      font-weight: bold;
      color: var(--primary-color);
      text-shadow: 0 0 3px var(--primary-color);
    }
  }

  .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover,
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    // background: var(--theme-off-bg-color);
    background: rgba(var(--rgb-theme-font), 0.05);
    transition: all 0.3s ease;
    border-radius: 4px;
    opacity: 1;
  }

  .ant-tabs-content {
    display: flex;
    flex: 1;
  }

  .ant-tabs-tabpane {
    display: flex;
    flex-direction: column;
  }

  .ant-tabs-content-holder {
    display: flex;
    flex: 1;
  }

  & > .ant-tabs-nav::before,
  & > div > .ant-tabs-nav::before {
    border-color: transparent;
  }

  .ant-tabs-ink-bar {
    background: transparent;
  }

  & > .ant-tabs-nav,
  & > div > .ant-tabs-nav {
    // margin-top: 8px;
    // margin-bottom: 8px;
    margin: 0;
    padding: 8px;
    border-block-end: 1px solid rgba(var(--rgb-theme-border), 0.5);
  }

  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: var(--theme-font-color);
    opacity: 0.3;
  }
}

@import 'scss/variables';
@import 'scss/functions';

@import '../node_modules/ng-zorro-antd/ng-zorro-antd.variable.min.css';
@import '~@fontsource/jetbrains-mono/index.css';

@import 'ngx-toastr/toastr';

@import 'scss/globals';
@import 'scss/framework';
@import 'scss/all';

a {
  color: var(--blue-color);
  text-decoration: none;
}

.no-link-link {
  color: var(--blue-color);
  cursor: pointer;
}

.link {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

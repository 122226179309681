.plugin-manager-wrapper {
  display: flex;
  gap: 4px;
}

.plugin-manager__list {
  width: 40%;
  min-width: 200px;
  border-right: 1px solid var(--theme-border-color);
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.plugin-manager__list-item {
  padding: 10px;
  border-radius: 4px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  cursor: pointer;
  color: var(--theme-font-color);

  &:hover {
    background: var(--theme-off-bg-color);
  }

  &--selected {
    border-color: var(--primary-color);
  }
}

.plugin-manager__list-item-name {
  font-weight: 500;
  font-size: fontsize(14);
  margin-bottom: 10px;
}

.plugin-manager__list-item-version {
  display: inline-block;
  font-size: fontsize(10);
  color: var(--theme-off-font-color);
}

.plugin-manager__list-item-description {
  font-size: fontsize(12);
}

.plugin-manager__item-details {
  flex: 1;
  padding: 0 10px;
}

.plugin-manager__item-details-name {
  font-size: fontsize(16);
  margin-bottom: 10px;
}

.plugin-manager__item-details-unique-name {
  display: inline-block;
  font-size: fontsize(12);
  background: var(--theme-off-bg-color);
}

.plugin-manager__item-details-actions {
  display: flex;
  gap: 4px;
  align-items: center;
}

.plugin-manager__item-details-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: fontsize(14);
  color: var(--theme-off-font-color);
}

.teams-wrapper {
}
.teams__input {
  border-radius: 4px;
  background-color: var(--theme-off-bg-color);
  border: 1px solid transparent;
  padding: 7px 10px;
  display: block;
  width: 100%;

  &:focus {
    border-color: var(--theme-border-color);
  }
}
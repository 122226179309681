app-header {
  --header-height: 50px;
  display: flex;
  // background: var(--header-bg-color);
  border-bottom: 1px solid rgba(var(--rgb-theme-border), 0.3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.header__logo-wrapper {
  position: relative;
  width: var(--header-height);
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__logo-wrapper--experimental {
  mix-blend-mode: luminosity;

  &:before {
    content: '< Dev >';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--header-bg-color);
    color: var(--theme-font-color);
    border: 1px solid rgba(var(--rgb-theme-border), 0.7);
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 8px;
    display: block;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
  }
}

.header__logo {
  width: 24px;
}

.header__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  gap: 8px;
}

.header__menu-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  gap: 4px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(var(--rgb-theme-font), 0.05);
  }
}

.header__actions {
  padding: 0 8px;
}

.header__environemnt-link {
  padding: 3px 5px;
  background: var(--theme-bg-color);
  border-radius: 4px;
  border: 1px solid rgba(var(--rgb-theme-border), 0.7);
}

.environments-dropdown__list {
  max-height: 400px;
  overflow: auto;
}

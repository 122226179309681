.app-input {
  border-radius: 4px;
  background-color: var(--theme-off-bg-color);
  border: 1px solid transparent;
  padding: 7px 10px;
  display: block;
  width: 100%;

  &:focus {
    border-color: var(--theme-border-color);
  }
}

.input {
  display: block;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: var(--input-border-color, rgba(var(--rgb-theme-font), 0.04));
  background: var(--input-bg, rgba(var(--rgb-theme-off-bg), 0.2));
  padding-block: 5px;
  padding-inline: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
  font-size: fontsize(14);
  margin: 0;
  width: 100%;
  transition: all 0.3s ease;

  &:hover {
    --input-bg: var(--input-hover-bg, rgba(var(--rgb-theme-off-bg), 0.3));
    border-color: var(--input-border-color, rgba(var(--rgb-theme-font), 0.05));
  }
  &:focus,
  &:focus-within {
    --input-bg: var(--input-focus-bg, rgba(var(--rgb-theme-off-bg), 0.8));
    // setting border-color instead of the css variable to override the default border-color
    border-color: var(--input-border-color, rgba(var(--rgb-theme-font), 0.1));
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
  }

  &--transparent {
    --input-bg: transparent;
  }
}

app-plugin-element {
  // overflow: auto;
}

.plugin__holder {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.plugin__hide-content {
  display: none !important;
}

.element__wrapper {
  overflow: auto;
  height: 100%;
}

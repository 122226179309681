app-pre-request-editor,
app-post-request-editor {
  height: 100%;
}

.request-script-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.request-script-editor__toggle-wrapper {
  padding: 0 5px;
}

.request-script-textarea {
  flex: 1;
  min-height: 0;
}

.window-switcher__container {
  -webkit-app-region: drag;
}

app-window-switcher {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 8px 0;
  overflow: auto;

  --shadow-height: 100%;
  // --shadow-color: rgba(0, 0, 0, 0.1);
  --shadow-weight: 9px;

  // background:
  //     //Left start and right start 'inside' container colors (they overlap the shadows)
  //   linear-gradient(90deg, var(--header-bg-color) 0%, rgba(255, 255, 255, 0)),
  //   linear-gradient(-90deg, var(--header-bg-color) 0%, rgba(255, 255, 255, 0)) 100% 0,
  //   //Left and right scroll shadows
  //   linear-gradient(90deg, var(--shadow-bg), rgba(0, 0, 0, 0)),
  //   linear-gradient(-90deg, var(--shadow-bg), rgba(0, 0, 0, 0)) 100% 0;

  background-repeat: no-repeat;
  // background-color: var(--header-bg-color);
  background-size:
    100px 100%,
    100px 100%,
    var(--shadow-weight) var(--shadow-height),
    var(--shadow-weight) var(--shadow-height);
  background-attachment: local, local, scroll, scroll;

  &.window-switcher__no-scrollbar {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--theme-bg-color);
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
}

.window-switcher__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  gap: 8px;
}

.window-switcher__item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  gap: 4px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease;
  opacity: 0.5;

  &:hover {
    background: rgba(var(--rgb-theme-font), 0.05);
    opacity: 0.7;

    .window-switcher__close {
      opacity: 1;
    }
  }
}

.window-switcher__item--active {
  @extend .glassy-border;
  background: rgba(var(--rgb-theme-font), 0.05);
  // color: var(--primary-color);
  &,
  &:hover {
    opacity: 1;
  }
}

.window-switcher__close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  aspect-ratio: 1;
  line-height: 1;
  transition: all 0.3s ease;
  border-radius: 4px;
  opacity: 0.3;

  &:hover {
    background: rgba(var(--rgb-theme-font), 0.15);
    color: var(--red-color);
  }
}

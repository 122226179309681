app-tag {
  display: inline-block;
  padding: 4px 8px;
  background: rgba(var(--rgb-tertiary), 0.15);
  color: var(--theme-font-color);
  border: 1px solid var(--tertiary-color);
  border-radius: 4px;
  line-height: 1;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

app-authorization-editor {
  height: 100%;
  overflow: auto;
}

.authorization__result-section {
  @extend .glassy-border;
  background: rgba(var(--rgb-theme-off-bg), 0.1);
  max-width: 400px;
  padding: 20px;
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
}

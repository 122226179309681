.app-side-menu {
  --side-menu-width: 50px;
  // background: var(--theme-bg-color);
  background: rgba(var(--rgb-theme-bg), 0.3);
  flex: 0 0 auto;
  order: -1;
  display: flex;
  flex-direction: row;
}

.side-menu__main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: var(--side-menu-width);
  min-width: var(--side-menu-width);
  padding-top: 15px;
  border-inline-end: 1px solid rgba(var(--rgb-theme-border), 0.7);
}

.side-menu__main-bottom {
  margin-top: auto;
}

.side-menu__extra {
}

.side-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--side-menu-width);
  aspect-ratio: 1;
  text-align: center;
  cursor: pointer;
  color: var(--theme-font-color);
  // opacity: .5;
  transition: all 0.3s ease;

  &:hover {
    // opacity: 1;
    color: var(--theme-font-color);

    .side-menu-item-icon {
      color: var(--primary-color);
      background: rgba(var(--rgb-primary), 0.15);
    }

    .side-menu-item-label {
      max-width: 300px;
      opacity: 1;
      color: var(--theme-font-color);
    }

    .side-menu-item-submenu {
      display: block;
    }
  }

  &--active {
    color: var(--primary-color);
  }
}

.side-menu-item-icon {
  transition: all 0.3s ease;
  padding: 8px;
  display: flex;
  border-radius: 4px;
}

.side-menu-item-label {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-inline-start: 4px;
  z-index: 10;
  // background: var(--theme-bg-color);
  background: rgba(var(--rgb-theme-bg), 0.7);
  backdrop-filter: blur(5px);
  max-width: 0;
  padding: 5px;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.side-menu-divider {
  border: 1px solid rgba(var(--rgb-theme-off-bg), 0.7);
  margin: 10px 0;
  opacity: 0.5;
}

.side-menu-item-submenu {
  @extend .glassy-border;
  display: none;
  position: absolute;
  top: 10px;
  left: 100%;
  z-index: 10;
  min-width: 100px;
  width: max-content;
  // background: var(--theme-bg-color);
  background: rgba(var(--rgb-theme-bg), 0.7);
  backdrop-filter: blur(5px);
  text-align: left;
  line-height: 1;
  box-shadow: 1px 2px 10px var(--shadow-bg);
  padding: 5px;
  border-radius: 4px;

  &--bottom {
    top: unset;
    bottom: 10px;
  }

  .submenu-item {
    display: block;
    color: var(--theme-font-color);
    padding: 7px 10px;
    transition: all 0.3s ease;
    border-radius: 4px;

    &:hover {
      color: var(--theme-font-color);
      background: rgba(var(--rgb-theme-off-bg), 0.5);
      text-decoration: none;
    }
  }

  .submenu-item-icon,
  .submenu-item-label {
    display: inline-block;
    vertical-align: middle;
  }
}

.submenu-item--upgrade {
  color: var(--primary-color);
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;

  &:hover {
    color: var(--primary-color);
    -webkit-text-fill-color: unset;
  }
}

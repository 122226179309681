app-icon,
.app-icon,
app-icon .app-icon {
  display: inline-block;
  margin: 0;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  stroke: currentColor; // For Lucide icons
}

app-icon .app-icon,
app-icon .app-icon svg {
  display: block;
}

.app-icon__injected svg {
  width: 16px;
  height: 16px;
}

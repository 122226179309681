.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: var(--theme-font-color);
  background: rgba(var(--rgb-theme-bg), 0.9);
  backdrop-filter: blur(20px);
  cursor: default;
}

.main-container-inner {
  flex: 1 1 auto;
  min-height: 1px;
  display: flex;
}

.content-container {
  position: relative;
}

.app-window__content {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  // padding: 5px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.main-content-area {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.main-content-row {
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  // gap: 0.5rem;
}

app-window {
  // width: 100%;
  // height: 100%;
  flex: 1 1 auto;
  display: flex;
  min-height: 1px;
  min-width: 0;
}

app-query-editor {
  @include main-content-col;
}

app-query-result {
  @include main-content-col;
  padding: 0;
}

app-doc-viewer {
  @include main-content-col;
  padding: 0;
}

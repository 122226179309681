.loading-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: var(--theme-bg-color);
  color: var(--theme-font-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &.styled {
    display: flex;
  }

  .loading-screen-logo-container {
    margin-bottom: 10px;
    max-width: 100px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .loading-screen-loading-indicator {
    text-align: center;
  }

  .loading-indicator-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 3px;
    background: var(--primary-color);
    animation: 1.5s loading-indicator-blink infinite linear;

    &:nth-child(1) {
      animation-delay: 0.5s;
    }

    &:nth-child(2) {
      animation-delay: 1s;
    }

    &:nth-child(3) {
      animation-delay: 1.5s;
    }
  }
}

@keyframes loading-indicator-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

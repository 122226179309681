.account-dialog__not-logged-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.account-dialog__not-logged-in__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 400px;

  p {
    text-align: center;
  }
}

.account-dialog__welcome {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.account-dialog__stats {
  letter-spacing: 0.5px;
  font-size: fontsize(12);
  margin-bottom: 16px;
}

.account-dialog__stats-title {
  font-weight: 600;
  font-size: fontsize(14);
}

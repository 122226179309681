$url-box-height: 45px;

app-url-box {
  --url-box-height: 45px;
  padding: 8px;
  padding-bottom: 0;
}

.url-box {
  // max-width: 900px;
  // margin: 0 auto 5px;
  height: var(--url-box-height);
  // background-color: var(--theme-off-bg-color);
  background: rgba(var(--rgb-theme-off-bg), 0.5);
  // border-block-end: 1px solid var(--theme-border-color);
  width: 100%;
  display: flex;
  border: 1px solid rgba(var(--rgb-theme-border), 0.3);
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(var(--rgb-black), 0.05);
  transition: all 0.3s ease;
  &:hover {
    border-color: rgba(var(--rgb-theme-border), 0.1);
  }

  &:focus,
  &:active,
  &:focus-within {
    border-color: var(--primary-color);
  }
}

.url-box__input-container {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 0; // needed to fix the overflow issue when there is a long url in the input
}

.url-box__input {
  flex: 1;
  height: calc(var(--url-box-height) - 16px);
  line-height: calc(var(--url-box-height) - 16px);
  font-size: fontsize(12);
  // box-shadow: 0 4px 30px rgba(var(--rgb-black), 0.05);
  padding: 0 8px;
  transition: all 0.3s ease;
  // background-color: var(--theme-off-bg-color);
  background: transparent;
  width: 100%;
  display: block;
  // padding-right: 150px;
}

.url-box__input-prepend {
  // position: absolute;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 4px;
  // width: 40px;
  text-align: center;

  // ~ .url-box__input {
  //   padding-left: 30px;
  // }
}

.url-box__input-actions {
  // position: absolute;
  // right: 0;
  // top: 0;
  // bottom: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
}

.url-box__button {
  background: transparent;
  color: var(--theme-font-color);
  font-size: fontsize(12);
  border: none;
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 8px;
  white-space: nowrap;
  transition: all 0.3s ease;

  &--send {
    border-radius: 0 4px 4px 0;
    min-width: 120px;
  }

  &--active {
    color: var(--primary-color);
  }

  &--disabled {
    color: var(--theme-font-color);
    opacity: 0.5;
  }

  &:hover {
    background: rgba(var(--rgb-primary), 0.15);
    color: var(--primary-color);
  }
}

.url-box__method-wrapper {
  display: flex;
  padding: 8px;
}

.url-box__button--method {
  min-width: 75px;
  background: rgba(var(--rgb-primary), 0.15);
  color: var(--primary-color);
  font-size: fontsize(12);
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 700;
  opacity: 0.9;

  &:hover {
    border-color: var(--primary-color);
    opacity: 1;
  }
}

.send-request__wrapper {
  display: flex;
}

.send-request__button {
  border-radius: 0 4px 4px 0;
  height: 100%;
}

// .btn.btn-outline-primary.url-box__button {
//   border-radius: 4px 0 0 4px;
//   border: 1px solid var(--theme-border-color);
//   border-right: 0;
//   background: var(--theme-bg-color);
//   color: var(--primary-color);
//   // height: var(--url-box-height);

//   &:hover {
//     border-color: var(--theme-off-border-color);
//     background: transparent;
//   }

//   &:active {
//     box-shadow: none;
//   }
// }

.url-box__connection-indicator {
  display: inline-block;
  opacity: 0.6;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }
}

.connection-indicator--connected {
  color: var(--green-color);
}

.connection-indicator--uncertain {
  color: var(--orange-color);
}

// https://www.youtube.com/watch?v=rdtTCVzTwSQ
.url-box__loader {
  position: relative;
}
.url-box__loader-inner {
  position: absolute;
  width: 100%;
  height: 3px;
  background: white;
  -webkit-box-reflect: below 1px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  // FIXME: would be nice to have the gradient mask here to taper the ends for a nicer effect,
  // but applying mask-image also masks off the other effects (blur, reflection)
  // since they appear outside the bounds of the element
  // mask-image: linear-gradient(
  //   to right,
  //   transparent 0%,
  //   black 5%,
  //   black 95%,
  //   transparent 100%
  // );
}
.url-box__loader-inner::before,
.url-box__loader-inner::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    90deg,
    var(--primary-color),
    var(--secondary-color),
    var(--tertiary-color),
    var(--primary-color),
    var(--secondary-color),
    var(--tertiary-color),
    /*end with the first color*/ var(--primary-color)
  );
  animation: loader 20s linear infinite;
  background-size: 500%;
}
.url-box__loader-inner::after {
  filter: blur(5px);
}

@keyframes loader {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 500% 0;
  }
}

.request-handler-params-section {
  margin-bottom: 20px;
}

.request-handler-params-title {
  text-transform: uppercase;
  font-size: fontsize(12);
  font-weight: bold;
  margin-bottom: 5px;
}
